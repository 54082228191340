var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "width": "100%",
      "max-width": "760"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("PG 사용·미사용")]), _c('v-spacer')], 1), _c('v-simple-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "width": "1024"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "150"
    }
  }, [_vm._v("PG")]), _c('th', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v("결제방식")]), _c('th'), _c('th', {
    attrs: {
      "width": "100"
    }
  }, [_vm._v("사용/미사용")])])]), _c('tbody', [_vm._l(_vm.payments, function (payment, index) {
    return [_c('tr', {
      key: index
    }, [_c('td', [payment.gateway == `toss` ? _c('span', [_vm._v("토스")]) : payment.gateway == `danal` ? _c('span', [_vm._v("다날")]) : payment.gateway == `korpay` ? _c('span', [_vm._v("코페이")]) : payment.gateway == `smartro` ? _c('span', [_vm._v("페이레터(스)")]) : _c('span', [_vm._v(_vm._s(_vm.gateway))])]), _c('td', [payment.method == `creditcard` ? _c('span', [_vm._v("신용카드")]) : _vm._e(), payment.method == `mobile` ? _c('span', [_vm._v("모바일")]) : _vm._e(), payment.method == `simple` ? _c('span', [_vm._v("간편결제")]) : _vm._e()]), _c('td'), _c('td', {
      staticClass: "text-center"
    }, [_c('v-switch', {
      staticClass: "d-inline-block mt-0 mx-auto",
      attrs: {
        "color": "red lighten-2",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: payment.disabled,
        callback: function ($$v) {
          _vm.$set(payment, "disabled", $$v);
        },
        expression: "payment.disabled"
      }
    })], 1)])];
  })], 2)]), _c('v-layout', {
    staticClass: "mt-4",
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }