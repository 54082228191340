<template>
    <console-content width="100%" max-width="760">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">PG 사용·미사용</v-col>
            <v-spacer />
        </v-row>

        <v-simple-table width="1024" class="elevation-1 mt-4">
            <thead>
                <tr>
                    <th width="150">PG</th>
                    <th width="100">결제방식</th>
                    <th></th>
                    <th width="100">사용/미사용</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="payment, index in payments">
                    <tr :key="index">
                        <td>
                            <span v-if="payment.gateway == `toss`">토스</span>
                            <span v-else-if="payment.gateway == `danal`">다날</span>
                            <span v-else-if="payment.gateway == `korpay`">코페이</span>
                            <span v-else-if="payment.gateway == `smartro`">페이레터(스)</span>
                            <span v-else>{{ gateway }}</span>
                        </td>
                        <td>
                            <span v-if="payment.method == `creditcard`">신용카드</span>
                            <span v-if="payment.method == `mobile`">모바일</span>
                            <span v-if="payment.method == `simple`">간편결제</span>
                        </td>
                        <td></td>
                        <td class="text-center">
                            <v-switch v-model="payment.disabled" color="red lighten-2" dense hide-details class="d-inline-block mt-0 mx-auto" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </v-simple-table>

        <v-layout justify-center class="mt-4"> <v-btn color="primary" @click="save">저장하기</v-btn> </v-layout>
    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";

export default {
    components: {
        ConsoleContent
    },
    created() {
        this.init();
    },
    data() {
        return {
            siteInfo: null,
            setting: null,
            payments: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { siteInfo } = await api.site.info.get();
            this.siteInfo = siteInfo;

            var { setting } = await api.console.gshop.setting.get();
            this.setting = setting;

            var payments = [
                ...siteInfo.paymentGateways.map((gateway, index) => ({ gateway, method: siteInfo.paymentMethods[index], disabled: !!setting.payments.find(payment => payment.gateway == gateway && payment.method == siteInfo.paymentMethods[index])?.disabled })),
                ...setting.payments.filter(payment => payment.disabled)
            ];
            this.payments = payments;
        },

        async save() {
            await api.console.gshop.setting.put({ _id: this.setting._id, payments: this.payments });
            alert("저장되었습니다.");
        },
    },
};
</script>
